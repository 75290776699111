// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-api-js": () => import("./../src/templates/api.js" /* webpackChunkName: "component---src-templates-api-js" */),
  "component---src-templates-audit-js": () => import("./../src/templates/audit.js" /* webpackChunkName: "component---src-templates-audit-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-contact-thanks-js": () => import("./../src/templates/contact-thanks.js" /* webpackChunkName: "component---src-templates-contact-thanks-js" */),
  "component---src-templates-gateway-js": () => import("./../src/templates/gateway.js" /* webpackChunkName: "component---src-templates-gateway-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-invoice-js": () => import("./../src/templates/invoice.js" /* webpackChunkName: "component---src-templates-invoice-js" */),
  "component---src-templates-job-js": () => import("./../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */)
}

